import React, { useEffect, useState } from "react"
import PageImageHeader from "../components/common/PageImageHeader"
import HeaderImage from "../images/music-submission-header.jpeg"
import styled from "styled-components"
import Layout from "../components/layout"
import { Link } from "gatsby"

import TwitterIcon from "../images/twitter30x30.jpeg"
import FacebookIcon from "../images/facebook30x30.jpeg"
import InstagramIcon from "../images/instagram30x30.jpeg"
import PinterestIcon from "../images/pinterest30x30.jpeg"
import LinkedInIcon from "../images/linkedin30x30.jpeg"
import TumblrIcon from "../images/tumblr30x30.jpeg"
import YouTubeIcon from "../images/youtube30x30.jpeg"
import PatreonIcon from "../images/patreon.jpeg"

import WebsiteIcon from "../images/website30x30.jpeg"
import EmailIcon from "../images/email30x30.jpeg"

import Bandcamp from "../images/bandcamp.png"
import Soundcloud from "../images/soundcloud.png"
import iTunes from "../images/iTunes.png"
import GooglePlay from "../images/googlePlay.png"
import Audiomack from "../images/audiomack.png"
import Spotify from "../images/spotify.png"
import MusicPlayerSmall from "../components/MusicPlayerSmall"
import SEO from "../components/seo"

const PageLayout = styled.div`
  font-family: "Roboto", sans-serif;
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  padding: 1rem;
  grid-template-columns: 50% 50%;

  @media (max-width: 1200px) {
    grid-template-columns: 100%;
  }

  .audio-container {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1200px) {
      grid-template-columns: 33% 33% 33%;
      width: 100%;
    }
    @media (max-width: 830px) {
      grid-template-columns: 100%;
      width: 100%;
    }
  }
  .music-title {
    width: 300px;
    font-size: 1em;
    margin: 1rem 0;
  }

  audio {
    width: 250px;
    height: 30px;
    border-radius: 20px;
    margin-right: 1.7rem;
  }
`

const Heading = styled.h2`
  font-family: "Orbitron", sans-serif;
  border-bottom: 3px solid red;
  margin: 2rem 0;
  max-width: 250px;
  font-weight: normal;
`

const SocialIcons = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    margin-right: 0.8rem;
  }
`

const HeaderImages = styled.div`
  .header-image {
    position: relative;
  }
  .band-image {
    position: absolute;
    top: 20%;
    width: 350px;
    max-height: 250px;
    left: 50%;
    margin-left: -175px;
  }
`

const ArtistHeader = styled.div`
  width: 100%;
  height: 400px;
  /* background-color: #6195bd; */
  /* margin: 1rem 0; */
  padding: 1.5rem 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ContactContainer = styled.div`
  img {
    margin: 0 0.5rem;
  }
`

export const query = graphql`
  query($slug: String!) {
    contentfulArtist(slug: { eq: $slug }) {
      bandName
      description {
        description
      }
      website
      email
      twitterUrl
      facebookUrl
      instagramUrl
      tumblr
      pinterest
      twitch
      youTube
      soundcloudUrl
      bandcampUrl
      audiomack
      iTunes
      googlePlay
      spotify
      description {
        description
      }

      music {
        title
        buyUrl
        mp3 {
          file {
            url
          }
        }
      }
      bandLogo {
        fixed {
          src
        }
      }
    }
  }
`

const ArtistTemplate = props => {
  const social = {
    website: props.data.contentfulArtist.website,
    email: props.data.contentfulArtist.email,
    twitter: props.data.contentfulArtist.twitterUrl,
    facebook: props.data.contentfulArtist.facebookUrl,
    instagram: props.data.contentfulArtist.instagramUrl,
    pinterest: props.data.contentfulArtist.pinterest,
    tumblr: props.data.contentfulArtist.tumblr,
    youtube: props.data.contentfulArtist.youTube,
    twitch: props.data.contentfulArtist.twitch,
    bandcamp: props.data.contentfulArtist.bandcampUrl,
    soundcloud: props.data.contentfulArtist.soundcloudUrl,
    audiomack: props.data.contentfulArtist.audiomack,
    itunes: props.data.contentfulArtist.iTunes,
    googleplay: props.data.contentfulArtist.googlePlay,
    spotify: props.data.contentfulArtist.spotify,
  }

  console.log(social)

  const [displayWebsite, setWebsite] = useState("")
  const [displayEmail, setEmail] = useState("")
  const [displayTwitter, setDisplayTwitter] = useState("")
  const [displayFacebook, setDisplayFacebook] = useState("")
  const [displayInstagram, setDisplayInstagram] = useState("")
  const [displayPinterest, setDisplayPinterest] = useState("")
  const [displayTumblr, setTumblr] = useState("")
  const [displayYoutube, setYoutube] = useState("")
  const [displayTwitch, setTwitch] = useState("")
  const [displayBandCamp, setBandCamp] = useState("")
  const [displaySoundcloud, setSoundcloud] = useState("")
  const [displayAudiomack, setAudiomack] = useState("")
  const [displayItunes, setItunes] = useState("")
  const [displayGooglePlay, setGooglePlay] = useState("")
  const [displaySpotify, setSpotify] = useState("")

  const showBandCamp = () => {
    if (social.bandcamp === "na") {
      setBandCamp("none")
    }
  }
  const showSoundcloud = () => {
    if (social.soundcloud === "na") {
      setSoundcloud("none")
    }
  }
  const showAudiomack = () => {
    if (social.audiomack === "na") {
      setAudiomack("none")
    }
  }
  const showItunes = () => {
    if (social.itunes === "na") {
      setItunes("none")
    }
  }
  const showGooglePlay = () => {
    if (social.googleplay === "na") {
      setGooglePlay("none")
    }
  }
  const showSpotify = () => {
    if (social.spotify === "na") {
      setSpotify("none")
    }
  }

  const showEmail = () => {
    if (social.website === "na") {
      setEmail("none")
    }
  }

  const showWebsite = () => {
    if (social.website === "na") {
      setWebsite("none")
    }
  }

  const showTwitter = () => {
    if (social.twitter === "na") {
      setDisplayTwitter("none")
    }
  }
  const showFacebook = () => {
    if (social.facebook === "na" || null) {
      setDisplayFacebook("none")
    }
  }
  const showInstagram = () => {
    if (social.instagram === "na" || null) {
      setDisplayInstagram("none")
    }
  }
  const showPinterest = () => {
    if (social.pinterest === "na" || null) {
      setDisplayPinterest("none")
    }
  }

  const showTumblr = () => {
    if (social.tumblr === "na" || null) {
      setTumblr("none")
    }
  }
  const showYoutube = () => {
    if (social.youtube === "na" || null) {
      setYoutube("none")
    }
  }
  const showTwitch = () => {
    if (social.twitch === "na") {
      setTwitch("none")
    }
  }

  useEffect(() => {
    showEmail()
    showWebsite()
    showBandCamp()
    showSoundcloud()
    showAudiomack()
    showItunes()
    showGooglePlay()
    showSpotify()
    showTwitter()
    showFacebook()
    showInstagram()
    showPinterest()
    showTumblr()
    showYoutube()
    showTwitch()
  })

  return (
    <>
      <SEO
        title={props.data.contentfulArtist.bandName}
        description={props.data.contentfulArtist.description.description}
      />
      <Layout>
        <ArtistHeader>
          <img src={props.data.contentfulArtist.bandLogo.fixed.src} />
        </ArtistHeader>
        {/* <HeaderImages>
        <PageImageHeader className="header-image" />
        <img
          className="band-image"
          src={props.data.contentfulArtist.bandLogo.fixed.src}
        />
      </HeaderImages> */}
        <PageLayout>
          <div className="left-column">
            <Heading>Music</Heading>
            {props.data.contentfulArtist.music.map(music => {
              return (
                <>
                  <div className="audio-container">
                    <div className="music-title">{music.title}</div>

                    {/* <audio style={{ width: "50px" }} controls id="player">
                      <source src={music.mp3.file.url} />
                    </audio> */}

                    {/* <MusicPlayerSmall audio={music.mp3.file.url} /> */}
                    <a
                      style={{ color: "black", textDecoration: "none" }}
                      href={music.buyUrl}
                    >
                      <i className="fas fa-shopping-cart"></i>
                    </a>
                  </div>
                </>
              )
            })}
          </div>
          <div className="right-column">
            <Heading>About {props.data.contentfulArtist.bandName}</Heading>
            <p>{props.data.contentfulArtist.description.description}</p>

            <Heading>Contact</Heading>
            <ContactContainer>
              <a
                href={props.data.contentfulArtist.website}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayWebsite }}
                  src={WebsiteIcon}
                  alt="website"
                  title="Twitter"
                />
              </a>
              <a
                href={props.data.contentfulArtist.email}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayEmail }}
                  src={EmailIcon}
                  alt="email"
                  title="email"
                />
              </a>
            </ContactContainer>

            <Heading>Social</Heading>
            <SocialIcons>
              <a
                href={props.data.contentfulArtist.twitterUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayTwitter }}
                  src={TwitterIcon}
                  alt="website"
                  title="Twitter"
                />
              </a>
              <a
                href={props.data.contentfulArtist.facebookUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayFacebook }}
                  src={FacebookIcon}
                  alt="website"
                  title="Facebook"
                />
              </a>
              <a
                href={props.data.contentfulArtist.instagramUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayInstagram }}
                  src={InstagramIcon}
                  alt="website"
                  title="Instagram"
                />
              </a>
              <a
                href={props.data.contentfulArtist.pinterestUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayPinterest }}
                  src={PinterestIcon}
                  alt="website"
                  title="Pinterest"
                />
              </a>
              <a
                href={props.data.contentfulArtist.tumblr}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayTumblr }}
                  src={TumblrIcon}
                  alt="website"
                  title="Tumblr"
                />
              </a>

              <a
                href={props.data.contentfulArtist.youTube}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ display: displayYoutube }}
                  src={YouTubeIcon}
                  alt="website"
                  title="YouTube"
                />
              </a>

              {/* <a
              href={props.data.contentfulArtist.youTubeUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ display: displayYoutube }}
                src={PatreonIcon}
                alt="patreon"
                title="patreon"
              />
            </a> */}
            </SocialIcons>
            <Heading>Distribution</Heading>
            <a
              href={props.data.contentfulArtist.soundcloudUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ display: displaySoundcloud }}
                src={Soundcloud}
                alt="soundcloud"
              />
            </a>
            <a
              href={props.data.contentfulArtist.bandcampUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ display: displayBandCamp }}
                src={Bandcamp}
                alt="bandcamp"
              />
            </a>
            <a
              href={props.data.contentfulArtist.iTunes}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ display: displayItunes }}
                src={iTunes}
                alt="iTunes"
              />
              <img
                style={{ display: displayGooglePlay }}
                src={GooglePlay}
                alt="googleplay"
              />
            </a>
            <a
              href={props.data.contentfulArtist.audiomack}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ display: displayAudiomack }}
                src={Audiomack}
                alt="Audiomack"
              />
            </a>
            <a
              href={props.data.contentfulArtist.spotify}
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ display: displaySpotify }}
                src={Spotify}
                alt="spotify"
              />
            </a>
          </div>
        </PageLayout>
      </Layout>
    </>
  )
}

export default ArtistTemplate
